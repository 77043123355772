import React from 'react';

function MySVGLogo({  width, height, fill }) {
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 175 69" enableBackground="new 0 0 175 69"  fill={fill} width={width} height={height} xmlSpace="preserve">
			<rect x="-91.611" y="-28.676" display="none" fill="#474EA1" stroke="#000000" strokeMiterlimit="10" width="340" height="126.352" />
			<rect x="260.628" y="-28.889" fill="#474EA1" stroke="#000000" strokeMiterlimit="10" width={"340"} height="126.352" />
			<g>
				<g>
					<path d="M354.345,54.452h-3.616c-0.165,0-0.321,0.065-0.437,0.188c-0.082,0.083-0.189,0.231-0.189,0.462v19.422
			c0,0.166,0.073,0.33,0.173,0.42c0.115,0.134,0.279,0.206,0.453,0.206h3.616c0.255,0,0.411-0.131,0.453-0.182
			c0.123-0.124,0.197-0.278,0.197-0.444V55.103c0-0.174-0.066-0.329-0.189-0.462C354.674,54.518,354.518,54.452,354.345,54.452z"/>
					<path d="M383.035,62.631h-16.952c-0.338,0-0.609-0.116-0.856-0.355c-0.248-0.246-0.354-0.518-0.354-0.873v-1.4
			c0-0.353,0.115-0.632,0.354-0.871c0.247-0.247,0.519-0.354,0.856-0.354h21.301c0.115,0,0.28-0.032,0.437-0.189
			c0.124-0.124,0.189-0.279,0.189-0.437v-3.063c0-0.116-0.032-0.281-0.189-0.446c-0.124-0.114-0.271-0.181-0.437-0.181h-21.301
			c-1.672,0-3.122,0.602-4.308,1.788c-1.187,1.186-1.788,2.636-1.788,4.307v0.296c0,1.672,0.601,3.122,1.788,4.315
			c1.186,1.188,2.636,1.789,4.308,1.789h16.952c0.338,0,0.61,0.114,0.848,0.362c0.247,0.246,0.362,0.527,0.362,0.864v1.426
			c0,0.337-0.115,0.609-0.354,0.857c-0.246,0.248-0.518,0.354-0.856,0.354h-21.302c-0.165,0-0.313,0.065-0.445,0.188
			c-0.115,0.124-0.181,0.28-0.181,0.454V74.5c0,0.181,0.065,0.345,0.181,0.452c0.124,0.126,0.28,0.198,0.445,0.198h21.302
			c1.672,0,3.129-0.601,4.316-1.795c1.186-1.195,1.788-2.643,1.788-4.316v-0.281c0-1.688-0.602-3.146-1.788-4.332
			C386.156,63.23,384.707,62.631,383.035,62.631z"/>
					<path d="M490.974,54.452h-22.405c-1.688,0-3.146,0.602-4.332,1.786c-1.187,1.187-1.788,2.646-1.788,4.332v8.459
			c0,1.69,0.602,3.147,1.788,4.335c1.186,1.186,2.644,1.786,4.332,1.786h22.405c0.256,0,0.412-0.131,0.453-0.182
			c0.123-0.124,0.197-0.288,0.197-0.469v-3.039c0-0.116-0.032-0.289-0.188-0.436c-0.125-0.125-0.28-0.189-0.462-0.189h-22.405
			c-0.354,0-0.625-0.116-0.873-0.363c-0.247-0.246-0.362-0.528-0.362-0.874v-9.604c0-0.338,0.115-0.617,0.362-0.864
			c0.239-0.247,0.52-0.363,0.873-0.363h22.405c0.172,0,0.337-0.065,0.462-0.188c0.124-0.125,0.188-0.272,0.188-0.438v-3.04
			c0-0.174-0.064-0.329-0.188-0.462C491.304,54.518,491.146,54.452,490.974,54.452z"/>
					<path d="M344.468,70.836h-22.405c-0.354,0-0.626-0.116-0.873-0.363c-0.248-0.246-0.363-0.528-0.363-0.874v-2.637
			h22.981c0.182,0,0.346-0.064,0.461-0.189c0.124-0.122,0.19-0.279,0.19-0.46v-3.04c0-0.173-0.066-0.337-0.19-0.461
			c-0.124-0.115-0.28-0.181-0.453-0.181h-22.981v-0.034h-4.885v6.426c0,1.69,0.601,3.146,1.788,4.333
			c1.186,1.185,2.644,1.789,4.333,1.789h22.405c0.256,0,0.412-0.135,0.453-0.184c0.124-0.123,0.198-0.288,0.198-0.47v-3.039
			c0-0.116-0.033-0.288-0.189-0.436C344.806,70.9,344.641,70.836,344.468,70.836z"/>
					<path d="M344.921,54.633c-0.124-0.115-0.28-0.181-0.453-0.181h-22.405c-1.688,0-3.147,0.602-4.333,1.786
			c-0.552,0.553-0.979,1.178-1.285,1.869l-0.288,0.653h28.304c0.172,0,0.336-0.067,0.46-0.19c0.124-0.123,0.189-0.271,0.189-0.438
			v-3.039C345.11,54.922,345.044,54.765,344.921,54.633L344.921,54.633z"/>
				</g>
				<g>
					<g>
						<path d="M375.942,22.466l25.635-24.893c1.392-1.352,3.616-1.343,4.991,0.024l18.534,18.369
				c0.124,0.116,0.278,0.19,0.453,0.19h13.047c0.568,0,0.856-0.693,0.453-1.096l-9.844-9.844l7.867-7.644
				c1.394-1.352,3.616-1.343,4.99,0.024l18.534,18.369c0.125,0.116,0.28,0.19,0.452,0.19h13.049c0.568,0,0.856-0.693,0.452-1.096
				l-27.8-27.8c-1.779-1.78-4.652-1.78-6.433-0.009L425.769,1.766l-14.515-14.515c-1.779-1.779-4.654-1.779-6.433-0.008
				l-37.784,37.784c-0.296,0.288-0.09,0.8,0.322,0.8h107.487v-3.361H375.942z"/>
					</g>
					<rect x="367.681" y="32.788" width="107.156" height="3.361" />
				</g>
				<path d="M423.298,60.57c0-1.671-0.602-3.129-1.796-4.322c-1.194-1.194-2.644-1.796-4.325-1.796h-22.405
		c-0.172,0-0.338,0.065-0.461,0.188c-0.082,0.083-0.189,0.231-0.189,0.462v19.422c0,0.166,0.074,0.33,0.172,0.42
		c0.124,0.134,0.289,0.2,0.47,0.2h22.405c1.672,0,3.13-0.604,4.324-1.789c1.195-1.187,1.796-2.636,1.796-4.316v-0.298
		c0-1.507-0.486-2.834-1.45-3.953c0.964-1.112,1.45-2.447,1.45-3.954V60.57H423.298z M418.405,61.419
		c0,0.339-0.115,0.617-0.371,0.865c-0.247,0.247-0.527,0.37-0.865,0.37h-16.532l-2.916,4.317h19.448
		c0.346,0,0.618,0.113,0.865,0.362c0.248,0.247,0.362,0.518,0.362,0.873v1.4c0,0.354-0.115,0.627-0.362,0.873
		c-0.247,0.248-0.527,0.364-0.873,0.364h-18.163v-2.456v-1.409v-4.316V58.8h18.163c0.346,0,0.618,0.116,0.865,0.363
		c0.248,0.248,0.362,0.511,0.362,0.848v1.408H418.405z"/>
				<path d="M455.662,56.238c-1.195-1.185-2.645-1.786-4.316-1.786h-16.937c-1.688,0-3.146,0.602-4.331,1.786
		c-1.187,1.187-1.789,2.636-1.789,4.308V74.5c0,0.181,0.075,0.354,0.175,0.444c0.081,0.091,0.229,0.2,0.469,0.2h3.616
		c0.164,0,0.328-0.066,0.428-0.184c0.123-0.123,0.197-0.288,0.197-0.47v-2.322v-3.534v-4.315v-3.616v-0.727
		c0-0.353,0.116-0.632,0.355-0.87c0.246-0.248,0.519-0.355,0.872-0.355h16.936c0.338,0,0.617,0.116,0.866,0.363
		c0.246,0.246,0.361,0.52,0.361,0.871v4.334h-17.602l-2.381,4.315h19.974v5.856c0,0.256,0.133,0.412,0.182,0.453
		c0.124,0.126,0.28,0.2,0.445,0.2h3.616c0.256,0,0.411-0.135,0.453-0.184c0.123-0.123,0.197-0.288,0.197-0.47V60.538
		C457.458,58.874,456.856,57.425,455.662,56.238z"/>
				<g>
					<path d="M501.501,55.094c0-0.172-0.066-0.329-0.189-0.461c-0.123-0.115-0.271-0.181-0.437-0.181h-3.616
			c-0.173,0-0.337,0.065-0.462,0.188c-0.124,0.124-0.188,0.281-0.188,0.462v19.422c0,0.172,0.074,0.337,0.172,0.42
			c0.125,0.134,0.29,0.2,0.471,0.2h3.617c0.162,0,0.327-0.066,0.427-0.184c0.123-0.123,0.198-0.28,0.198-0.445v-7.554h0.074
			L501.501,55.094z"/>
					<path d="M525.596,54.641L525.596,54.641c-0.125-0.123-0.288-0.181-0.462-0.181h-3.6
			c-0.173,0-0.338,0.066-0.47,0.197c-0.116,0.124-0.173,0.281-0.173,0.453v7.537h-17.216l-2.685,4.332h19.9v7.553
			c0,0.164,0.066,0.322,0.173,0.429c0.116,0.125,0.287,0.199,0.47,0.199h3.6c0.257,0,0.411-0.134,0.453-0.183
			c0.123-0.123,0.197-0.281,0.197-0.445V55.11C525.785,54.922,525.72,54.765,525.596,54.641z"/>
				</g>
			</g>
			<path d="M536.089,44.315c-3.109,0-5.645,2.535-5.645,5.645s2.535,5.646,5.645,5.646s5.645-2.537,5.645-5.646
	S539.208,44.315,536.089,44.315z M540.466,49.96c0,2.406-1.961,4.367-4.367,4.367c-2.404,0-4.365-1.961-4.365-4.367
	c0-2.416,1.961-4.365,4.365-4.365C538.505,45.595,540.466,47.556,540.466,49.96z"/>
			<g>
				<path d="M535.298,50.503v2.512h-1.203v-6.445h1.961c0.445,0,0.813,0.043,1.127,0.141
		c0.314,0.088,0.574,0.217,0.77,0.379c0.205,0.164,0.346,0.357,0.443,0.586c0.098,0.227,0.141,0.477,0.141,0.748
		c0,0.217-0.031,0.422-0.098,0.617c-0.064,0.195-0.15,0.367-0.27,0.531c-0.119,0.162-0.271,0.291-0.445,0.41
		c-0.172,0.119-0.379,0.217-0.605,0.283c0.15,0.086,0.281,0.205,0.389,0.367l1.615,2.383h-1.084c-0.107,0-0.195-0.021-0.26-0.064
		c-0.076-0.043-0.131-0.098-0.184-0.174l-1.355-2.057c-0.053-0.076-0.107-0.131-0.162-0.164c-0.064-0.031-0.15-0.053-0.27-0.053
		H535.298L535.298,50.503z M535.298,49.646h0.748c0.227,0,0.422-0.031,0.596-0.086c0.162-0.055,0.303-0.131,0.41-0.238
		c0.109-0.098,0.186-0.217,0.238-0.357c0.055-0.141,0.076-0.293,0.076-0.455c0-0.324-0.107-0.574-0.324-0.758
		c-0.217-0.174-0.541-0.26-0.986-0.26h-0.77v2.154H535.298z"/>
			</g>
			<path d="M166.932,39.893c-3.581,0-6.5,2.919-6.5,6.5c0,3.58,2.919,6.502,6.5,6.502s6.5-2.922,6.5-6.502
	C173.432,42.812,170.524,39.893,166.932,39.893z M171.972,46.393c0,2.771-2.258,5.029-5.029,5.029c-2.769,0-5.027-2.259-5.027-5.029
	c0-2.782,2.258-5.027,5.027-5.027C169.714,41.365,171.972,43.624,171.972,46.393z"/>
			<g>
				<path d="M166.021,47.018v2.893h-1.385v-7.423h2.258c0.513,0,0.935,0.05,1.297,0.162
		c0.363,0.102,0.662,0.25,0.886,0.437c0.236,0.188,0.398,0.412,0.511,0.676c0.112,0.26,0.162,0.548,0.162,0.861
		c0,0.249-0.036,0.485-0.113,0.711c-0.074,0.224-0.173,0.422-0.311,0.611c-0.137,0.186-0.313,0.335-0.512,0.472
		c-0.198,0.138-0.437,0.249-0.697,0.327c0.173,0.098,0.324,0.235,0.447,0.422l1.86,2.744h-1.248c-0.124,0-0.225-0.024-0.299-0.074
		c-0.087-0.05-0.15-0.113-0.211-0.2l-1.561-2.368c-0.06-0.088-0.123-0.151-0.187-0.189c-0.074-0.036-0.173-0.061-0.31-0.061H166.021
		L166.021,47.018z M166.021,46.03h0.861c0.261,0,0.486-0.035,0.686-0.099c0.187-0.063,0.349-0.15,0.473-0.274
		c0.125-0.113,0.213-0.25,0.274-0.411c0.063-0.162,0.088-0.338,0.088-0.525c0-0.373-0.124-0.661-0.374-0.873
		c-0.25-0.199-0.623-0.298-1.136-0.298h-0.887v2.48H166.021z"/>
			</g>
			<g>
				<g>
					<path d="M31.028,52.033h-2.64c-0.121,0-0.235,0.048-0.319,0.138c-0.06,0.061-0.138,0.169-0.138,0.337v14.179
			c0,0.12,0.054,0.241,0.126,0.307c0.084,0.098,0.204,0.151,0.331,0.151h2.64c0.187,0,0.3-0.097,0.331-0.134
			c0.09-0.09,0.145-0.204,0.145-0.324V52.508c0-0.127-0.048-0.24-0.138-0.337C31.269,52.081,31.155,52.033,31.028,52.033z"/>
					<path d="M51.973,58.004H39.598c-0.247,0-0.445-0.085-0.625-0.26c-0.181-0.18-0.259-0.378-0.259-0.637v-1.022
			c0-0.257,0.084-0.462,0.259-0.637c0.18-0.18,0.379-0.258,0.625-0.258h15.55c0.084,0,0.205-0.024,0.319-0.139
			c0.09-0.09,0.138-0.204,0.138-0.319v-2.236c0-0.084-0.024-0.205-0.138-0.325c-0.09-0.083-0.198-0.132-0.319-0.132h-15.55
			c-1.221,0-2.28,0.438-3.145,1.305c-0.867,0.866-1.305,1.925-1.305,3.145v0.216c0,1.221,0.438,2.279,1.305,3.151
			c0.865,0.866,1.924,1.306,3.145,1.306h12.375c0.247,0,0.445,0.083,0.619,0.265c0.18,0.18,0.264,0.385,0.264,0.631v1.04
			c0,0.246-0.084,0.446-0.258,0.626c-0.18,0.181-0.378,0.259-0.625,0.259H36.422c-0.12,0-0.228,0.048-0.325,0.138
			c-0.084,0.09-0.132,0.204-0.132,0.331v2.218c0,0.133,0.048,0.253,0.132,0.331c0.091,0.092,0.205,0.146,0.325,0.146h15.551
			c1.221,0,2.285-0.439,3.151-1.312c0.866-0.872,1.305-1.93,1.305-3.151v-0.205c0-1.232-0.439-2.297-1.305-3.162
			C54.252,58.441,53.194,58.004,51.973,58.004z"/>
					<path d="M130.772,52.033h-16.356c-1.233,0-2.297,0.439-3.163,1.305c-0.866,0.866-1.305,1.931-1.305,3.162v6.176
			c0,1.233,0.439,2.297,1.305,3.164c0.865,0.865,1.93,1.305,3.163,1.305h16.356c0.187,0,0.3-0.097,0.331-0.134
			c0.09-0.09,0.145-0.21,0.145-0.343V64.45c0-0.085-0.024-0.212-0.138-0.317c-0.091-0.092-0.205-0.139-0.337-0.139h-16.356
			c-0.258,0-0.457-0.085-0.638-0.265c-0.18-0.181-0.264-0.387-0.264-0.639v-7.012c0-0.246,0.084-0.449,0.264-0.631
			c0.175-0.18,0.38-0.265,0.638-0.265h16.356c0.126,0,0.246-0.048,0.337-0.138c0.09-0.091,0.138-0.198,0.138-0.318v-2.22
			c0-0.127-0.048-0.24-0.138-0.337C131.013,52.081,130.898,52.033,130.772,52.033z"/>
					<path d="M23.818,63.994H7.462c-0.259,0-0.457-0.085-0.638-0.265c-0.18-0.181-0.265-0.387-0.265-0.639v-1.925
			h16.777c0.132,0,0.252-0.048,0.337-0.139c0.09-0.09,0.139-0.204,0.139-0.336v-2.22c0-0.125-0.048-0.246-0.139-0.336
			c-0.09-0.084-0.205-0.132-0.331-0.132H6.566v-0.025H3v4.691c0,1.233,0.438,2.297,1.305,3.163c0.866,0.865,1.93,1.306,3.163,1.306
			h16.356c0.187,0,0.3-0.098,0.331-0.133c0.09-0.091,0.145-0.211,0.145-0.343v-2.22c0-0.084-0.024-0.21-0.138-0.317
			C24.065,64.041,23.945,63.994,23.818,63.994z"/>
					<path d="M24.149,52.166c-0.09-0.085-0.204-0.133-0.331-0.133H7.462c-1.233,0-2.297,0.439-3.163,1.305
			c-0.403,0.403-0.715,0.859-0.938,1.364l-0.21,0.477h20.662c0.126,0,0.246-0.049,0.336-0.139c0.09-0.091,0.138-0.199,0.138-0.319
			v-2.219C24.287,52.376,24.239,52.261,24.149,52.166L24.149,52.166z"/>
				</g>
				<g>
					<g>
						<path d="M46.795,28.683L65.509,10.51c1.016-0.987,2.64-0.98,3.644,0.018l13.53,13.41
				c0.09,0.084,0.205,0.138,0.331,0.138h9.525c0.415,0,0.625-0.505,0.331-0.8l-7.186-7.186l5.743-5.58
				c1.017-0.987,2.64-0.98,3.644,0.018l13.53,13.41c0.091,0.084,0.205,0.138,0.331,0.138h9.525c0.416,0,0.625-0.505,0.331-0.8
				L98.492,2.981c-1.299-1.299-3.397-1.299-4.695-0.006L83.17,13.571L72.575,2.975c-1.299-1.299-3.398-1.299-4.697-0.006
				L40.294,30.553c-0.216,0.21-0.066,0.583,0.235,0.583h78.469v-2.454H46.795z"/>
					</g>
					<rect x="40.764" y="36.218" width="78.228" height="2.453" />
				</g>
				<path d="M81.366,56.5c0-1.22-0.439-2.284-1.311-3.156c-0.872-0.871-1.93-1.311-3.157-1.311H60.542
		c-0.126,0-0.247,0.048-0.337,0.138c-0.06,0.061-0.138,0.169-0.138,0.337v14.179c0,0.12,0.054,0.241,0.126,0.307
		c0.091,0.098,0.211,0.146,0.343,0.146h16.356c1.221,0,2.286-0.44,3.157-1.306c0.872-0.866,1.311-1.924,1.311-3.151v-0.217
		c0-1.101-0.355-2.068-1.059-2.886c0.704-0.813,1.059-1.787,1.059-2.888V56.5H81.366z M77.794,57.119
		c0,0.247-0.084,0.451-0.271,0.632c-0.18,0.18-0.385,0.271-0.631,0.271H64.823l-2.128,3.151h14.197c0.252,0,0.451,0.083,0.631,0.265
		c0.181,0.18,0.265,0.378,0.265,0.637v1.022c0,0.259-0.084,0.458-0.265,0.638C77.343,63.916,77.139,64,76.886,64H63.627v-1.793
		v-1.029v-3.15v-2.819h13.259c0.252,0,0.451,0.084,0.631,0.265c0.181,0.181,0.265,0.372,0.265,0.619v1.027H77.794z"/>
				<path d="M104.992,53.338c-0.872-0.865-1.93-1.305-3.15-1.305H89.478c-1.233,0-2.297,0.439-3.163,1.305
		c-0.866,0.866-1.305,1.924-1.305,3.144v10.187c0,0.133,0.054,0.26,0.126,0.325c0.06,0.066,0.168,0.146,0.343,0.146h2.64
		c0.119,0,0.24-0.048,0.312-0.133c0.09-0.091,0.145-0.211,0.145-0.343v-1.696v-2.58v-3.15v-2.64v-0.53
		c0-0.257,0.084-0.462,0.259-0.636c0.18-0.181,0.378-0.259,0.637-0.259h12.363c0.248,0,0.451,0.085,0.632,0.265
		c0.18,0.18,0.265,0.38,0.265,0.637v3.163H89.881l-1.738,3.15h14.582v4.276c0,0.187,0.097,0.3,0.132,0.33
		c0.091,0.092,0.205,0.146,0.325,0.146h2.64c0.187,0,0.3-0.098,0.331-0.133c0.09-0.091,0.144-0.211,0.144-0.343V56.477
		C106.304,55.262,105.865,54.204,104.992,53.338z"/>
				<g>
					<path d="M138.458,52.502c0-0.126-0.049-0.241-0.139-0.336c-0.09-0.085-0.198-0.133-0.319-0.133h-2.64
			c-0.126,0-0.246,0.048-0.337,0.138c-0.09,0.09-0.138,0.205-0.138,0.337v14.179c0,0.126,0.054,0.246,0.126,0.307
			c0.091,0.098,0.211,0.146,0.343,0.146h2.64c0.119,0,0.24-0.048,0.312-0.133c0.09-0.091,0.145-0.205,0.145-0.326v-5.514h0.054
			L138.458,52.502z"/>
					<path d="M156.047,52.171L156.047,52.171c-0.091-0.09-0.21-0.132-0.337-0.132h-2.628
			c-0.125,0-0.246,0.049-0.342,0.144c-0.085,0.092-0.126,0.205-0.126,0.331v5.502h-12.568l-1.96,3.162h14.529v5.515
			c0,0.12,0.048,0.235,0.126,0.313c0.084,0.091,0.21,0.145,0.342,0.145h2.628c0.187,0,0.3-0.098,0.331-0.133
			c0.09-0.09,0.144-0.205,0.144-0.325V52.514C156.185,52.376,156.137,52.261,156.047,52.171z"/>
				</g>
			</g>
		</svg>
	);
}

export default React.memo(MySVGLogo);



