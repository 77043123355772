/* social icons */
export const FacebookIcon=(props)=>{
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{width:'1.063rem', height:'1.063rem'}}>
            <path d="M16.7344 8.625C16.7344 4.07617 13.0488 0.390625 8.5 0.390625C3.95117 0.390625 0.265625 4.07617 0.265625 8.625C0.265625 12.7422 3.25391 16.1621 7.20508 16.7598V11.0156H5.11328V8.625H7.20508V6.83203C7.20508 4.77344 8.43359 3.61133 10.293 3.61133C11.2227 3.61133 12.1523 3.77734 12.1523 3.77734V5.80273H11.123C10.0938 5.80273 9.76172 6.43359 9.76172 7.09766V8.625H12.0527L11.6875 11.0156H9.76172V16.7598C13.7129 16.1621 16.7344 12.7422 16.7344 8.625Z" fill="#4B4B4B"/>
        </svg>
    );
}
export const InstagramIcon=(props)=>{
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{width:'.938rem', height:'1rem'}}>
            <path d="M7.5 3.80664C5.375 3.80664 3.68164 5.5332 3.68164 7.625C3.68164 9.75 5.375 11.4434 7.5 11.4434C9.5918 11.4434 11.3184 9.75 11.3184 7.625C11.3184 5.5332 9.5918 3.80664 7.5 3.80664ZM7.5 10.1152C6.13867 10.1152 5.00977 9.01953 5.00977 7.625C5.00977 6.26367 6.10547 5.16797 7.5 5.16797C8.86133 5.16797 9.95703 6.26367 9.95703 7.625C9.95703 9.01953 8.86133 10.1152 7.5 10.1152ZM12.3477 3.67383C12.3477 3.17578 11.9492 2.77734 11.4512 2.77734C10.9531 2.77734 10.5547 3.17578 10.5547 3.67383C10.5547 4.17188 10.9531 4.57031 11.4512 4.57031C11.9492 4.57031 12.3477 4.17188 12.3477 3.67383ZM14.8711 4.57031C14.8047 3.375 14.5391 2.3125 13.6758 1.44922C12.8125 0.585938 11.75 0.320312 10.5547 0.253906C9.32617 0.1875 5.64062 0.1875 4.41211 0.253906C3.2168 0.320312 2.1875 0.585938 1.29102 1.44922C0.427734 2.3125 0.162109 3.375 0.0957031 4.57031C0.0292969 5.79883 0.0292969 9.48438 0.0957031 10.7129C0.162109 11.9082 0.427734 12.9375 1.29102 13.834C2.1875 14.6973 3.2168 14.9629 4.41211 15.0293C5.64062 15.0957 9.32617 15.0957 10.5547 15.0293C11.75 14.9629 12.8125 14.6973 13.6758 13.834C14.5391 12.9375 14.8047 11.9082 14.8711 10.7129C14.9375 9.48438 14.9375 5.79883 14.8711 4.57031ZM13.2773 12.0078C13.0449 12.6719 12.5137 13.1699 11.8828 13.4355C10.8867 13.834 8.5625 13.7344 7.5 13.7344C6.4043 13.7344 4.08008 13.834 3.11719 13.4355C2.45312 13.1699 1.95508 12.6719 1.68945 12.0078C1.29102 11.0449 1.39062 8.7207 1.39062 7.625C1.39062 6.5625 1.29102 4.23828 1.68945 3.24219C1.95508 2.61133 2.45312 2.11328 3.11719 1.84766C4.08008 1.44922 6.4043 1.54883 7.5 1.54883C8.5625 1.54883 10.8867 1.44922 11.8828 1.84766C12.5137 2.08008 13.0117 2.61133 13.2773 3.24219C13.6758 4.23828 13.5762 6.5625 13.5762 7.625C13.5762 8.7207 13.6758 11.0449 13.2773 12.0078Z" fill="#4B4B4B"/>
        </svg>
    );
}
export const LinkedinIcon=(props)=>{
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{width:'.938rem', height:'1rem'}}>
            <path d="M13.875 0.1875H1.0918C0.527344 0.1875 0.0625 0.685547 0.0625 1.2832V14C0.0625 14.5977 0.527344 15.0625 1.0918 15.0625H13.875C14.4395 15.0625 14.9375 14.5977 14.9375 14V1.2832C14.9375 0.685547 14.4395 0.1875 13.875 0.1875ZM4.54492 12.9375H2.35352V5.86523H4.54492V12.9375ZM3.44922 4.86914C2.71875 4.86914 2.1543 4.30469 2.1543 3.60742C2.1543 2.91016 2.71875 2.3125 3.44922 2.3125C4.14648 2.3125 4.71094 2.91016 4.71094 3.60742C4.71094 4.30469 4.14648 4.86914 3.44922 4.86914ZM12.8125 12.9375H10.5879V9.48438C10.5879 8.6875 10.5879 7.625 9.45898 7.625C8.29688 7.625 8.13086 8.52148 8.13086 9.45117V12.9375H5.93945V5.86523H8.03125V6.82812H8.06445C8.36328 6.26367 9.09375 5.66602 10.1562 5.66602C12.3809 5.66602 12.8125 7.16016 12.8125 9.05273V12.9375Z" fill="#4B4B4B"/>
        </svg>
    );
}
export const TiltokIcon=(props)=>{
    return (
        <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{width:'.938rem', height:'1.125rem'}}>
            <path d="M14.9375 7.09766V4.20898C14.9043 4.20898 14.9043 4.20898 14.9043 4.20898C14.1074 4.20898 13.3438 3.97656 12.7129 3.54492C11.7832 2.94727 11.1191 1.98438 10.9199 0.888672C10.8535 0.65625 10.8535 0.423828 10.8535 0.191406C10.8535 0.158203 10.8535 0.158203 10.8535 0.125H7.93164V11.7461C7.93164 13.1074 6.80273 14.2363 5.44141 14.2363C4.08008 14.2363 2.95117 13.1074 2.95117 11.7461C2.95117 10.3848 4.08008 9.25586 5.44141 9.25586C5.70703 9.25586 5.93945 9.28906 6.20508 9.38867V6.40039C5.93945 6.36719 5.70703 6.33398 5.44141 6.33398C2.45312 6.33398 0.0625 8.75781 0.0625 11.7461C0.0625 14.7344 2.45312 17.125 5.44141 17.125C8.42969 17.125 10.8535 14.7344 10.8535 11.7461V5.80273C11.9824 6.63281 13.377 7.09766 14.9043 7.09766H14.9375Z" fill="#4B4B4B"/>
        </svg>
    );
}
export const PinterestIcon=(props)=>{
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{width:'1.063rem', height:'1.125rem'}}>
            <path d="M16.7261 9.125C16.7261 13.6738 13.0405 17.3594 8.4917 17.3594C7.62842 17.3594 6.79834 17.2598 6.03467 16.9941C6.3667 16.4629 6.86475 15.5664 7.06396 14.8359C7.16357 14.4707 7.56201 12.877 7.56201 12.877C7.82764 13.4082 8.62451 13.8398 9.45459 13.8398C11.9448 13.8398 13.7378 11.5488 13.7378 8.72656C13.7378 6.00391 11.5132 3.94531 8.65771 3.94531C5.10498 3.94531 3.2124 6.33594 3.2124 8.92578C3.2124 10.1543 3.84326 11.6484 4.87256 12.1465C5.03857 12.2129 5.13818 12.1797 5.17139 12.0137C5.17139 11.9141 5.3374 11.3496 5.40381 11.084C5.40381 11.0176 5.40381 10.918 5.3374 10.8516C5.00537 10.4531 4.73975 9.68945 4.73975 8.95898C4.73975 7.16602 6.10107 5.40625 8.4585 5.40625C10.4507 5.40625 11.8784 6.76758 11.8784 8.75977C11.8784 10.9844 10.7495 12.5117 9.28857 12.5117C8.4917 12.5117 7.89404 11.8477 8.06006 11.0508C8.29248 10.0547 8.75732 8.99219 8.75732 8.29492C8.75732 7.66406 8.42529 7.13281 7.72803 7.13281C6.89795 7.13281 6.23389 7.99609 6.23389 9.125C6.23389 9.85547 6.46631 10.3535 6.46631 10.3535C6.46631 10.3535 5.66943 13.8066 5.50342 14.4375C5.3374 15.168 5.40381 16.1641 5.47021 16.7949C2.41553 15.5996 0.257324 12.6445 0.257324 9.125C0.257324 4.57617 3.94287 0.890625 8.4917 0.890625C13.0405 0.890625 16.7261 4.57617 16.7261 9.125Z" fill="#4B4B4B"/>
        </svg>        
    );
}
export const YoutubeIcon=(props)=>{
    return (
        <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{width:'1.188rem', height:'.813rem'}}>
            <path d="M18.166 2.27539C17.9668 1.47852 17.3359 0.847656 16.5723 0.648438C15.1445 0.25 9.5 0.25 9.5 0.25C9.5 0.25 3.82227 0.25 2.39453 0.648438C1.63086 0.847656 1 1.47852 0.800781 2.27539C0.402344 3.66992 0.402344 6.6582 0.402344 6.6582C0.402344 6.6582 0.402344 9.61328 0.800781 11.041C1 11.8379 1.63086 12.4355 2.39453 12.6348C3.82227 13 9.5 13 9.5 13C9.5 13 15.1445 13 16.5723 12.6348C17.3359 12.4355 17.9668 11.8379 18.166 11.041C18.5645 9.61328 18.5645 6.6582 18.5645 6.6582C18.5645 6.6582 18.5645 3.66992 18.166 2.27539ZM7.64062 9.34766V3.96875L12.3555 6.6582L7.64062 9.34766Z" fill="#4B4B4B"/>
        </svg>
    );
}
export const TwitterIcon=(props)=>{
    return (
        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{width:'1.063rem', height:'.938rem'}}>
            <path d="M15.2402 4.17188C15.9043 3.67383 16.502 3.07617 16.9668 2.37891C16.3691 2.64453 15.6719 2.84375 14.9746 2.91016C15.7051 2.47852 16.2363 1.81445 16.502 0.984375C15.8379 1.38281 15.0742 1.68164 14.3105 1.84766C13.6465 1.15039 12.75 0.751953 11.7539 0.751953C9.82812 0.751953 8.26758 2.3125 8.26758 4.23828C8.26758 4.50391 8.30078 4.76953 8.36719 5.03516C5.47852 4.86914 2.88867 3.47461 1.16211 1.38281C0.863281 1.88086 0.697266 2.47852 0.697266 3.14258C0.697266 4.33789 1.29492 5.40039 2.25781 6.03125C1.69336 5.99805 1.12891 5.86523 0.664062 5.59961V5.63281C0.664062 7.32617 1.85938 8.7207 3.45312 9.05273C3.1875 9.11914 2.85547 9.18555 2.55664 9.18555C2.32422 9.18555 2.125 9.15234 1.89258 9.11914C2.32422 10.5137 3.61914 11.5098 5.14648 11.543C3.95117 12.4727 2.45703 13.0371 0.830078 13.0371C0.53125 13.0371 0.265625 13.0039 0 12.9707C1.52734 13.9668 3.35352 14.5312 5.3457 14.5312C11.7539 14.5312 15.2402 9.25195 15.2402 4.63672C15.2402 4.4707 15.2402 4.33789 15.2402 4.17188Z" fill="#4B4B4B"/>
        </svg>
    );
}
/* left, right, up, down, angle and arrow icons */
export const AngleDown=({color="#4B4B4B"})=>{
    return (
        <svg width="16" height="16" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'1rem', height:'1rem'}}>
            <path d="M0.264625 10.8669C0.264625 10.4735 0.414785 10.0801 0.714665 9.78023C1.31442 9.18003 2.28782 9.18003 2.88758 9.78023L20.0224 26.9146L37.1569 9.78023C37.757 9.18003 38.73 9.18003 39.3302 9.78023C39.93 10.3804 39.93 11.3534 39.3302 11.9536L21.1091 30.1747C20.5094 30.7749 19.536 30.7749 18.9362 30.1747L0.715103 11.9536C0.414785 11.6537 0.264625 11.2603 0.264625 10.8669Z" fill={color}/>
        </svg>
    );
}
export const AngleDownXs=({color="#4B4B4B"})=>{
    return(
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'.75rem', height:'.438rem'}}>
            <path d="M6.25 6.40808C6.00391 6.40808 5.78516 6.32605 5.62109 6.16199L1.24609 1.78699C0.890625 1.45886 0.890625 0.884644 1.24609 0.556519C1.57422 0.20105 2.14844 0.20105 2.47656 0.556519L6.25 4.30261L9.99609 0.556519C10.3242 0.20105 10.8984 0.20105 11.2266 0.556519C11.582 0.884644 11.582 1.45886 11.2266 1.78699L6.85156 6.16199C6.6875 6.32605 6.46875 6.40808 6.25 6.40808Z" fill={color}/>
        </svg>
    )
}
export const AngleUp=({color="#4B4B4B"})=>{
    return (
        <svg width="16" height="16" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'1rem', height:'1rem'}}>
            <path d="M39.7354 29.1331C39.7354 29.5265 39.5852 29.9199 39.2853 30.2198C38.6856 30.82 37.7122 30.82 37.1124 30.2198L19.9776 13.0854L2.84315 30.2198C2.24295 30.82 1.26999 30.82 0.66979 30.2198C0.0700308 29.6196 0.0700308 28.6466 0.66979 28.0464L18.8909 9.82532C19.4906 9.22512 20.464 9.22512 21.0638 9.82532L39.2849 28.0464C39.5852 28.3463 39.7354 28.7397 39.7354 29.1331Z" fill={color}/>
        </svg>        
    );
}
export const AngleRightSm=({color="#03A9F4"})=>{
    return (
        <svg width="6" height="10" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'0.375rem', height:'0.625rem'}}>
        <path d="M7.07957 6.33789C7.48582 6.71289 7.48582 7.36914 7.07957 7.74414L2.07957 12.7441C1.70457 13.1504 1.04832 13.1504 0.673317 12.7441C0.267067 12.3691 0.267067 11.7129 0.673317 11.3379L4.95457 7.02539L0.673317 2.74414C0.267067 2.36914 0.267067 1.71289 0.673317 1.33789C1.04832 0.931641 1.70457 0.931641 2.07957 1.33789L7.07957 6.33789Z" fill={color} fillOpacity="1"/>
        </svg>
    );
}
export const AngleRight=({color="#949494"})=>{
    return (
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'0.5rem', height:'0.875rem'}}>
            <path d="M7.40448 6.39453C7.83612 6.79297 7.83612 7.49023 7.40448 7.88867L2.09198 13.2012C1.69354 13.6328 0.996277 13.6328 0.597839 13.2012C0.166199 12.8027 0.166199 12.1055 0.597839 11.707L5.14667 7.125L0.597839 2.57617C0.166199 2.17773 0.166199 1.48047 0.597839 1.08203C0.996277 0.650391 1.69354 0.650391 2.09198 1.08203L7.40448 6.39453Z" fill={color}/>
        </svg>
    );
}
export const AngleLeft=({color="#949494"})=>{
    return (
        <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'0.563rem', height:'0.875rem'}}>
            <path d="M0.862488 6.39453C0.430847 6.79297 0.430847 7.49023 0.862488 7.88867L6.17499 13.2012C6.57343 13.6328 7.27069 13.6328 7.66913 13.2012C8.10077 12.8027 8.10077 12.1055 7.66913 11.707L3.1203 7.125L7.66913 2.57617C8.10077 2.17773 8.10077 1.48047 7.66913 1.08203C7.27069 0.650391 6.57343 0.650391 6.17499 1.08203L0.862488 6.39453Z" fill={color} />
        </svg>
    );
}
export const AngleRightLg=({color="4B4B4B"})=>{
    return (        
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'2.5rem', height:'2.5rem'}}>
            <path d="M10.8672 39.7356C10.4738 39.7356 10.0804 39.5855 9.78047 39.2856C9.18027 38.6858 9.18027 37.7124 9.78047 37.1127L26.9149 19.9778L9.78047 2.84339C9.18027 2.2432 9.18027 1.27023 9.78047 0.670034C10.3807 0.070275 11.3536 0.070275 11.9538 0.670034L30.1749 18.8911C30.7751 19.4909 30.7751 20.4643 30.1749 21.064L11.9538 39.2851C11.654 39.5855 11.2606 39.7356 10.8672 39.7356Z" fill={color}/>
        </svg>
    );
}
export const AngleLeftLg=({color="4B4B4B"})=>{
    return (        
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'2.5rem', height:'2.5rem'}}>
            <path d="M29.1328 0.264381C29.5262 0.264381 29.9196 0.414541 30.2195 0.71442C30.8197 1.31418 30.8197 2.28758 30.2195 2.88734L13.0851 20.0222L30.2195 37.1566C30.8197 37.7568 30.8197 38.7298 30.2195 39.33C29.6193 39.9297 28.6464 39.9297 28.0462 39.33L9.82508 21.1089C9.22488 20.5091 9.22488 19.5357 9.82508 18.936L28.0462 0.714859C28.346 0.414541 28.7394 0.264381 29.1328 0.264381Z" fill={color} />
        </svg>
    );
}
export const RightArrow=({color='#fff', iconClass=''})=>{
    return (
        <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'1.063rem', height:'.625rem'}} className={iconClass}>
            <path d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z" fill={color} />
        </svg>
    );
}
/* Form and topbar icons */
export const PhoneIcon=(props)=>{
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{width:'1.063rem', height:'1.063rem'}}>
            <path d="M5.12476 0.939209C4.90601 0.345459 4.24976 0.064209 3.65601 0.220459L0.906006 0.970459C0.374756 1.12671 -0.000244141 1.59546 -0.000244141 2.15796C-0.000244141 9.90796 6.24976 16.158 13.9998 16.158C14.5623 16.158 15.031 15.783 15.1873 15.2517L15.9373 12.5017C16.0935 11.908 15.8123 11.2517 15.2185 11.033L12.2185 9.78296C11.7185 9.56421 11.1248 9.68921 10.781 10.1267L9.49976 11.658C7.31226 10.6267 5.53101 8.84546 4.49976 6.65796L6.03101 5.37671C6.46851 5.03296 6.59351 4.43921 6.37476 3.93921L5.12476 0.939209Z" fill="white"/>
        </svg>
    );
}
export const PhoneAltIcon=({color="#fff"})=>{
    return (
        <svg width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'1.375rem', height:'1.375rem'}}>
            <path d="M1.91211 17.5L7.02148 15.2969C7.63086 15.0625 8.33398 15.2031 8.70898 15.7188L10.7715 18.25C14.0527 16.6562 16.6777 14.0312 18.2715 10.75L15.7402 8.6875C15.2246 8.3125 15.084 7.60938 15.3184 7L17.5215 1.89062C17.8027 1.28125 18.5059 0.90625 19.1621 1.04688L23.8965 2.17188C24.5996 2.3125 25.0215 2.875 25.0215 3.57812C25.0215 15.3906 15.4121 25.0469 3.59961 25.0469C2.89648 25.0469 2.33398 24.5781 2.19336 23.875L1.06836 19.1875C0.927734 18.4844 1.30273 17.7812 1.91211 17.5Z" fill={color}/>
        </svg>
    );
}
export const SearchIcon=({color="#fff"})=>{
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'1.063rem', height:'1.063rem'}}>
            <path d="M12.9998 6.65796C12.9998 8.09546 12.531 9.43921 11.7498 10.5017L15.6873 14.4705C16.0935 14.8455 16.0935 15.5017 15.6873 15.8767C15.3123 16.283 14.656 16.283 14.281 15.8767L10.3123 11.908C9.24976 12.7205 7.90601 13.158 6.49976 13.158C2.90601 13.158 -0.000244141 10.2517 -0.000244141 6.65796C-0.000244141 3.09546 2.90601 0.157959 6.49976 0.157959C10.0623 0.157959 12.9998 3.09546 12.9998 6.65796ZM6.49976 11.158C8.96851 11.158 10.9998 9.15796 10.9998 6.65796C10.9998 4.18921 8.96851 2.15796 6.49976 2.15796C3.99976 2.15796 1.99976 4.18921 1.99976 6.65796C1.99976 9.15796 3.99976 11.158 6.49976 11.158Z" fill={color}/>
        </svg>
    );
}
export const EnvelopOpenIcon=({color="#fff"})=>{
    return (
        <svg width="23" height="22" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'1.438rem', height:'1.375rem'}}>
            <path d="M23.1777 7.64062C22.0059 6.75 21.0684 6 15.4902 1.96875C14.6934 1.40625 13.1465 0 12.0684 0H12.0215C10.9434 0 9.39648 1.40625 8.59961 1.96875C3.02148 6 2.08398 6.70312 0.912109 7.64062C0.349609 8.0625 0.0683594 8.71875 0.0683594 9.42188V21.75C0.0683594 23.0156 1.05273 24 2.31836 24H21.8184C23.0371 24 24.0684 23.0156 24.0684 21.7969V9.42188C24.0684 8.71875 23.7402 8.0625 23.1777 7.64062ZM14.2715 17.25C13.6152 17.7656 12.8184 18 12.0684 18C11.2715 18 10.4746 17.7656 9.81836 17.25L3.06836 11.9531V9.79688C4.05273 9 5.22461 8.10938 10.3809 4.35938C10.5215 4.26562 10.709 4.125 10.8965 3.98438C11.1309 3.79688 11.6465 3.375 12.0684 3.14062C12.4434 3.375 12.959 3.79688 13.1934 3.98438C13.3809 4.125 13.5684 4.26562 13.709 4.40625C18.8184 8.10938 20.0371 9 21.0684 9.79688V11.9531L14.2715 17.25Z" fill={color}/>
        </svg>
    );
}
export const EnvelopOpenSm=({color="#ACACAC"})=>{
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'.938rem', height:'1rem'}}>
            <path d="M14.4434 5.65039C13.7109 5.09375 13.125 4.625 9.63867 2.10547C9.14062 1.75391 8.17383 0.875 7.5 0.875H7.4707C6.79688 0.875 5.83008 1.75391 5.33203 2.10547C1.8457 4.625 1.25977 5.09375 0.527344 5.65039C0.175781 5.91406 0 6.32422 0 6.76367V14C0 15.0547 0.820312 15.875 1.875 15.875H13.125C14.1504 15.875 15 15.0547 15 14V6.76367C15 6.32422 14.7949 5.91406 14.4434 5.65039ZM13.5938 14C13.5938 14.2637 13.3594 14.4688 13.125 14.4688H1.875C1.61133 14.4688 1.40625 14.2637 1.40625 14V8.99023L5.39062 12.3301C5.97656 12.7988 6.70898 13.0625 7.5 13.0625C8.26172 13.0625 8.99414 12.7988 9.58008 12.3008L13.5938 8.99023V14ZM13.5938 7.17383L8.70117 11.2168C8.02734 11.7734 6.94336 11.7734 6.26953 11.2168L1.40625 7.17383V6.76367C2.08008 6.23633 2.63672 5.79688 6.18164 3.24805C6.26953 3.16016 6.35742 3.10156 6.47461 3.01367C6.70898 2.80859 7.20703 2.42773 7.5 2.31055C7.76367 2.42773 8.26172 2.80859 8.49609 3.01367C8.61328 3.10156 8.70117 3.16016 8.81836 3.24805C12.334 5.79688 12.8906 6.23633 13.5938 6.76367V7.17383Z" fill={color}/>
        </svg>
    );
}
export const LocationIcon=({color="#fff", bgColor="#03a9f4" })=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="28" height="28" viewBox="0 0 256 256" style={{width:'1.75rem', height:'1.75rem'}}>
            <defs></defs>
            <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                <path d="M 45 90 c -1.415 0 -2.725 -0.748 -3.444 -1.966 l -4.385 -7.417 C 28.167 65.396 19.664 51.02 16.759 45.189 c -2.112 -4.331 -3.175 -8.955 -3.175 -13.773 C 13.584 14.093 27.677 0 45 0 c 17.323 0 31.416 14.093 31.416 31.416 c 0 4.815 -1.063 9.438 -3.157 13.741 c -0.025 0.052 -0.053 0.104 -0.08 0.155 c -2.961 5.909 -11.41 20.193 -20.353 35.309 l -4.382 7.413 C 47.725 89.252 46.415 90 45 90 z" strokeLinecap="round" transform=" matrix(1 0 0 1 0 0) " fill={color} />
                <path d="M 45 45.678 c -8.474 0 -15.369 -6.894 -15.369 -15.368 S 36.526 14.941 45 14.941 c 8.474 0 15.368 6.895 15.368 15.369 S 53.474 45.678 45 45.678 z" transform=" matrix(1 0 0 1 0 0)" fill={bgColor} strokeLinecap="round" />
            </g>
        </svg>
    );
}
export const PlayIcon=({color="#4B4B4B"})=>{
    return (
        <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'.688rem', height:'.813rem'}}>
            <path d="M9.97656 5.10156C10.3594 5.34766 10.6055 5.78516 10.6055 6.22266C10.6055 6.6875 10.3594 7.125 9.97656 7.34375L2.10156 12.1562C1.69141 12.4023 1.17188 12.4297 0.761719 12.1836C0.351562 11.9648 0.105469 11.5273 0.105469 11.0352V1.41016C0.105469 0.945312 0.351562 0.507812 0.761719 0.289062C1.17188 0.0429688 1.69141 0.0429688 2.10156 0.316406L9.97656 5.10156Z" fill={color}/>
        </svg>
    );
}
export const PauseIcon=({color="#4B4B4B"})=>{
    return (
        <svg height="20" width="20" version="1.1" viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg"  style={{width:'1.25rem', height:'1.25rem'}} >
            <g><path d="M224,435.8V76.1c0-6.7-5.4-12.1-12.2-12.1h-71.6c-6.8,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6   C218.6,448,224,442.6,224,435.8z" fill={color} /><path d="M371.8,64h-71.6c-6.7,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6c6.7,0,12.2-5.4,12.2-12.2V76.1   C384,69.4,378.6,64,371.8,64z" fill={color} /></g>
        </svg>
    );
}
export const CloseIcon=({color="#4B4B4B"})=>{
    return (
        <svg fill={color} width="24" height="24" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 64 64" style={{width:'1.5rem', height:'1.5rem'}}>
            <path d="M35.2,32L59.6,7.6c0.9-0.9,0.9-2.3,0-3.2c-0.9-0.9-2.3-0.9-3.2,0L32,28.8L7.6,4.4c-0.9-0.9-2.3-0.9-3.2,0
            c-0.9,0.9-0.9,2.3,0,3.2L28.8,32L4.4,56.4c-0.9,0.9-0.9,2.3,0,3.2c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7L32,35.2l24.4,24.4
            c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7c0.9-0.9,0.9-2.3,0-3.2L35.2,32z"/>
        </svg>
    );
}
export const CloseSm=({color="#4B4B4B"})=>{
    return (
        <svg fill={color} width="16" height="16" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 64 64" style={{width:'1rem', height:'1rem'}}>
            <path d="M35.2,32L59.6,7.6c0.9-0.9,0.9-2.3,0-3.2c-0.9-0.9-2.3-0.9-3.2,0L32,28.8L7.6,4.4c-0.9-0.9-2.3-0.9-3.2,0
            c-0.9,0.9-0.9,2.3,0,3.2L28.8,32L4.4,56.4c-0.9,0.9-0.9,2.3,0,3.2c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7L32,35.2l24.4,24.4
            c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7c0.9-0.9,0.9-2.3,0-3.2L35.2,32z"/>
        </svg>
    );
}
export const DownloadIcon=({color="white"})=>{
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'1.063rem', height:'1.063rem'}}>
            <path d="M15.3092 9.23706C15.8405 9.23706 16.3092 8.76831 16.3092 8.23706C16.3092 7.67456 15.8405 7.23706 15.3092 7.23706L7.71545 7.23706L9.9967 4.92456C10.403 4.54956 10.403 3.89331 9.9967 3.51831C9.6217 3.11206 8.96545 3.11206 8.59045 3.51831L4.59045 7.51831C4.1842 7.89331 4.1842 8.54956 4.59045 8.92456L8.59045 12.9246C8.96545 13.3308 9.6217 13.3308 9.9967 12.9246C10.403 12.5496 10.403 11.8933 9.9967 11.5183L7.71545 9.23706L15.3092 9.23706ZM5.3092 2.23706C5.3092 1.11206 4.40295 0.23706 3.3092 0.23706L2.3092 0.23706C1.1842 0.23706 0.309203 1.11206 0.309203 2.23706L0.309203 14.2371C0.309202 15.3308 1.1842 16.2371 2.3092 16.2371L3.3092 16.2371C4.40295 16.2371 5.3092 15.3308 5.3092 14.2371L5.3092 11.0496L3.8717 9.64331C3.09045 8.86206 3.09045 7.58081 3.8717 6.79956L5.3092 5.39331L5.3092 2.23706ZM2.0592 13.7371C2.0592 13.2996 2.3717 12.9871 2.8092 12.9871C3.21545 12.9871 3.5592 13.2996 3.5592 13.7371C3.5592 14.1433 3.21545 14.4871 2.8092 14.4871C2.3717 14.4871 2.0592 14.1433 2.0592 13.7371Z" fill={color}/>
        </svg>
    );
}
export const LanguageIcon=({color="#000"})=>{
    return(
        <svg height="22" viewBox="0 0 48 48" width="22" xmlns="http://www.w3.org/2000/svg" style={{width:'1.375rem', height:'1.375rem'}}>
            <path d="M0 0h48v48h-48z" fill="none"/>
            <path d="M23.99 4c-11.05 0-19.99 8.95-19.99 20s8.94 20 19.99 20c11.05 0 20.01-8.95 20.01-20s-8.96-20-20.01-20zm13.85 12h-5.9c-.65-2.5-1.56-4.9-2.76-7.12 3.68 1.26 6.74 3.81 8.66 7.12zm-13.84-7.93c1.67 2.4 2.97 5.07 3.82 7.93h-7.64c.85-2.86 2.15-5.53 3.82-7.93zm-15.48 19.93c-.33-1.28-.52-2.62-.52-4s.19-2.72.52-4h6.75c-.16 1.31-.27 2.64-.27 4 0 1.36.11 2.69.28 4h-6.76zm1.63 4h5.9c.65 2.5 1.56 4.9 2.76 7.13-3.68-1.26-6.74-3.82-8.66-7.13zm5.9-16h-5.9c1.92-3.31 4.98-5.87 8.66-7.13-1.2 2.23-2.11 4.63-2.76 7.13zm7.95 23.93c-1.66-2.4-2.96-5.07-3.82-7.93h7.64c-.86 2.86-2.16 5.53-3.82 7.93zm4.68-11.93h-9.36c-.19-1.31-.32-2.64-.32-4 0-1.36.13-2.69.32-4h9.36c.19 1.31.32 2.64.32 4 0 1.36-.13 2.69-.32 4zm.51 11.12c1.2-2.23 2.11-4.62 2.76-7.12h5.9c-1.93 3.31-4.99 5.86-8.66 7.12zm3.53-11.12c.16-1.31.28-2.64.28-4 0-1.36-.11-2.69-.28-4h6.75c.33 1.28.53 2.62.53 4s-.19 2.72-.53 4h-6.75z"/>
        </svg>
    )
}